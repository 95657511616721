import request from '@/utils/request'
import serviceConfig from '@/network/serviceConfig'
// 获取所有文档
export function getDocuments(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/knowledgeBase/documents`,
        method: 'get'
    })
}

// 增加文档
export function addDocuments(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/knowledgeBase/documents`,
        method: 'post',
        data: params.data
    })
}
// 移动文档
export function location(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/knowledgeBase/documents/${params.documentId}/location`,
        method: 'PUT',
        data: params.data
    })
}
// 获取单个文档
export function getContent(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/knowledgeBase/documents/${params.documentId}/content`,
        method: 'get'
    })
}
// 获取单个文档信息
export function getDocument(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/knowledgeBase/documents/${params.documentId}`,
        method: 'get'
    })
}
// 获取单个文档(未登录)
export function getContentUnlogin(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/knowledgeBase/public/${params.pathId}/documents/content`,
        method: 'get'
    })
}
// 获取单个文档信息（未登录）
export function getDocumentUnlogin(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/knowledgeBase/public/${params.pathId}/documents`,
        method: 'get'
    })
}
// 保存文档更改(未登录)
export function editDocumentUnlogin(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/knowledgeBase/public/${params.pathId}/documents/content`,
        method: 'put',
        params: params.query,
        data: params.data
    })
}
// 重命名文档(未登录)
export function updateTitleUnlogin(params) {
    return request({
        url: `${serviceConfig['file-manage-service']}/knowledgeBase/public/${params.pathId}/documents/title`,
        method: 'put',
        data: params.data
    })
}

// 重命名文档
export function updateTitle(params) {
    return request({
        url: `${serviceConfig['file-manage-service']}/${params.projectId}/knowledgeBase/documents/${params.documentId}/title`,
        method: 'put',
        data: params.data
    })
}
// 保存文档更改
export function editDocument(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/knowledgeBase/documents/${params.documentId}/content`,
        method: 'put',
        data: params.data
    })
}
// 软删除文档
export function deleteDocument(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/knowledgeBase/documents/${params.documentId}`,
        method: 'DELETE',
    })
}
// 查看回收站
export function getRecycleBin(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/knowledgeBase/documents/recycleBin`,
        method: 'get',
        params: params.params
    })
}
// 恢复回收站
export function recoverBin(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/knowledgeBase/documents/recycleBin/batch?documentId=${params.documentId}`,
        method: 'put',

    })
}
// 彻底删除
export function deleteBatch(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/knowledgeBase/documents/recycleBin/batch?documentId=${params.documentId}`,
        method: 'delete',
    })
}
//查看思维导图转化结果
export function ifConverted(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/knowledgeBase/documents/${params.documentId}/mapping/ifConverted`,
        method: 'get',
    })
}
//一键转为思维导图
export function convert(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/knowledgeBase/documents/${params.documentId}/mapping/convert`,
        method: 'post',
        data: params.data
    })
}
//文档界面查看思维导图转化结果
export function distribution(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/knowledgeBase/documents/${params.documentId}/mapping/distribution`,
        method: 'get'
    })
}
//一键覆盖思维导图
export function cover(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/knowledgeBase/documents/${params.documentId}/mapping/cover`,
        method: 'post',
        data: params.data
    })
}
//生成新的思维导图
export function newFile(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/knowledgeBase/documents/${params.documentId}/mapping/newFile`,
        method: 'post',
        data: params.data
    })
}
//收藏
export function collections(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/knowledgeBase/documents/collections/${params.documentId}`,
        method: 'post',
    })
}
//取消收藏
export function deleteCollections(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/knowledgeBase/documents/collections/${params.documentId}`,
        method: 'delete',
    })
}
//查看单个文档是否被收藏
export function collected(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/knowledgeBase/documents/${params.documentId}/collected`,
        method: 'get',
    })
}
//查看单个文档是否被收藏
export function getCollections(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/knowledgeBase/documents/collections`,
        method: 'get',
    })
}
//文章内@人
export function sendAt(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/knowledgeBase/documents/${params.documentId}/at`,
        method: 'post',
        data: params.data
    })
}
//文章评论
export function getComments(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/knowledgeBase/documents/${params.documentId}/comments`,
        method: 'get',
        params: params.query
    })
}
//发起文章评论
export function sendComments(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/knowledgeBase/documents/${params.documentId}/comments`,
        method: 'post',
        data: params.data
    })
}
//删除评论
export function delComment(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/knowledgeBase/documents/${params.documentId}/comments/${params.commentId}`,
        method: 'delete',
    })
}
//评论点赞
export function like(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/knowledgeBase/documents/${params.documentId}/comments/${params.commentId}/like`,
        method: 'post',
    })
}
//评论删除点赞
export function unlike(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/knowledgeBase/documents/${params.documentId}/comments/${params.commentId}/unlike`,
        method: 'delete',
    })
}
//取词评论修改状态
export function rightCommentChangeStatus(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/knowledgeBase/documents/${params.documentId}/comments/${params.commentId}`,
        method: 'put',
        params: params.query
    })
}
//知识库是否付费查询
export function payFuture(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/knowledgeBase/payFuture`,
        method: 'get',
    })
}
//获取页面权限设置
export function getSetting(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/knowledgeBase/setting/${params.documentId}`,
        method: 'get',
    })
}
//获取页面权限设置
export function getAuth(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/knowledgeBase/documents/${params.documentId}/auth`,
        method: 'get',
    })
}
//修改页面权限
export function editSetting(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/knowledgeBase/setting/${params.settingId}`,
        method: 'put',
        data: params.data
    })
}
//查看页面设置是否与父级相同
export function checkFather(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/knowledgeBase/setting/${params.settingId}/check`,
        method: 'put',
        data: params.data
    })
}
//转移文章所有权
export function ownership(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/knowledgeBase/documents/${params.documentId}/ownership?newAccountId=${params.newAccountId}`,
        method: 'put',
    })
}
//快速分享链接
export function quickShare(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/knowledgeBase/documents/${params.documentId}/quickShare`,
        method: 'post',
        data: params.data
    })
}
//获取互联网分享链接
export function internetShare(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/knowledgeBase/documents/${params.documentId}/internetShare/link`,
        method: 'get'
    })
}
//检查互联网链接有效性
export function linkValidInter(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/knowledgeBase/public/internetShare/${params.pathId}/linkValid`,
        method: 'get',
        params: params.query
    })
}
//检查互联网链接有效性包含token
export function linkTokenValid(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/knowledgeBase/public/internetShare/${params.pathId}/tokenValid`,
        method: 'get',
        params: params.query
    })
}
// 检查快速分享链接
export function linkValidQuick(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/tenant/knowledgeBase/quickShare/${params.pathId}/linkValid`,
        method: 'get'
    })
}
// 外部网页提问
export function sendQuestions(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/knowledgeBase/public/${params.pathId}/questions`,
        method: 'post',
        data: params.data
    })
}
// 外部提问获取
export function getQuestions(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/knowledgeBase/${params.documentId}/questions`,
        method: 'get'
    })
}
// 外部提问获取
export function changeQuestionsStatuses(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/knowledgeBase/${params.documentId}/questions/${params.questionId}`,
        method: 'put',
        params: params.query
    })
}
// 外部链接上传
export function imageOnInternet(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/imageOnInternet`,
        method: 'post',
        params: params
    })
}
// 互联网外部链接上传
export function pubImageOnInternet(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/knowledgeBase/public/imageOnInternet/${params.pathId}`,
        method: 'post',
        params: params
    })
}
// 通过路径判断是快速分享还是互联网分享
export function internetOrquick(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/knowledgeBase/public/share/${params.pathId}`,
        method: 'get',
    })
}
// 未登录状态下的oss验证
export function get_unloginAuthorization(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/knowledgeBase/public/stsCredentials/${params.pathId}`,

        method: "get",
        params: {
            token: params.token
        }
    });
}
// 申请查看权限
export function apply_access(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/knowledgeBase/documents/${params.documentId}/applyAccess`,
        method: 'post',
        params: {}
    })
}
// 知识库导出word
export function wordFile(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/knowledgeBase/wordFile`,
        method: 'post',
        data: params.data
    })
}
// 知识库导出pdf
export function pdfFile(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/knowledgeBase/pdfFile`,
        method: 'post',
        data: params.data
    })
}

// 知识库查看历史版本
export function getDocumentHistories(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/knowledgeBase/documents/${params.documentId}/histories`,
        method: 'get'
    })
}
// 知识库发布历史版本
export function postDocumentVersion(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/knowledgeBase/documents/${params.documentId}/publish`,
        method: 'post',
        data: { content: params.content }
    })
}
// 知识库恢复历史版本
export function recoverDocumentVersion(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/knowledgeBase/documents/${params.documentId}/histories/${params.versionId}`,
        method: 'put',
    })
}

// 通过节点key 返回关联知识库的相关
export function mappingNodes(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/knowledgeBase/documents/mapping/nodes/${params.nodeKey}`,
        method: 'get'
    })
}
// 通过文件key 返回关联知识库的相关信息
export function mappingFiles(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/knowledgeBase/documents/mapping/files/${params.fileKey}`,
        method: 'get'
    })
}
// 复制或转移知识库文档
export function copyDocument(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/knowledgeBase/documents/${params.documentId}/copyCrossProject`,
        method: 'post',
        data: params.data
    })
}
// 获取源页面
export function getSourceDocument(params) {
    return request({
        url: `/${serviceConfig['mindmap-service']}/${params.projectId}/knowledgeBase/documents/${params.documentId}/origin`,
        method: 'get'
    })
}
// 知识库同项目复制
export function copyDocumentInternal(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/knowledgeBase/documents/${params.documentId}/copy`,
        method: 'post',
        data: params.data
    })
}
// 获取当前用户在租户下拥有特定职位权限的项目列表
export function getAuthProjects(params) {
    return request({
        url: `/${serviceConfig['project-service']}/tenant/projectMember/jobAuthorities?accountId=${params.accountId}&jobAuthority=${params.jobAuthority}`,
        method: 'get'
    })
}
// 关注页面
export function followDoc(params) {
    return request({
        url: `/${serviceConfig['mindmap-service']}/${params.projectId}/knowledgeBase/documents/${params.documentId}/follow`,
        method: 'post',
    })
}
// 取消关注
export function unfollowDoc(params) {
    return request({
        url: `/${serviceConfig['mindmap-service']}/${params.projectId}/knowledgeBase/documents/${params.documentId}/unfollow`,
        method: 'DELETE',
    })
}
// 查看当前用户是否关注页面
export function isFollowed(params) {
    return request({
        url: `/${serviceConfig['mindmap-service']}/${params.projectId}/knowledgeBase/documents/${params.documentId}/followed`,
        method: 'get'
    })
}
// 查看当前用所有关注页面
export function getAllFollowed(params) {
    return request({
        url: `/${serviceConfig['mindmap-service']}/${params.projectId}/knowledgeBase/documents/follows`,
        method: 'get'
    })
}
// 退出编辑
export function exitEdit(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/knowledgeBase/documents/${params.documentId}/exit?isUpdated=${params.isUpdated}`,
        method: 'post',
    })
}
// 外部退出编辑
export function pubExitEdit(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/knowledgeBase/public/${params.pathId}/exit?isUpdated=${params.isUpdated}`,
        method: 'post',
    })
}
// 获取互联网可查看可编辑文章列表
export function getInternetShowList(params) {
    return request({
        url: `${serviceConfig['file-manage-service']}/${params.projectId}/knowledgeBase/settings/internet`,
        method: 'get',
    })
}

// 外部文章订阅
export function postSubscribe(params) {
    return request({
        url: `${serviceConfig['file-manage-service']}/knowledgeBase/public/${params.pathId}/subscribe?email=${params.email}`,
        method: 'post',
    })
}
// 外部文章取消订阅
export function deleteSubscribe(params) {
    return request({
        url: `${serviceConfig['file-manage-service']}/knowledgeBase/public/subscribe/${params.subscribeId}?email=${params.email}`,
        method: 'delete',
    })
}

// 
export function get_web_desc(data) {
    return request({
        url: `${serviceConfig['file-manage-service']}/parseUrl`,
        method: 'post',
        data

    })
}

// 更新es
export function uploadES(projectId,documentId,data) {
    return request({
        url: `/${serviceConfig['mindmap-service-v2']}/${projectId}/knowledgeBase/documents/${documentId}/es`,
        method: 'PUT',
        data: data
    })
}

// 更新es（未登录）
export function uploadESUnLogin(pathId,data) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/knowledgeBase/public/${pathId}/es`,
        method: 'PUT',
        data: data
    })
}

// 所有文章
export function getAll(params) {
    return request({
        url: `${serviceConfig['mindmap-service-v2']}/transfer`,
        method: 'get',
        params

    })
}

export function fabu(projectId,documentId) {
    return request({
        url: `${serviceConfig['mindmap-service-v2']}/${projectId}/knowledgeBase/documents/${documentId}`,
        method: 'post',
    })
}

export function putAll(data) {
    return request({
        url: `${serviceConfig['mindmap-service-v2']}/transfer`,
        method: 'put',
        data

    })
}